@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }



    .jidelni-listek-mobile-pozadi-1{
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: 387vh;
        margin-top: 27%;
    }
    

    .jidelni-liskte-main-text-1{
        position: relative;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 23px;
        color: black;
        margin-top: 12%;
        font-weight: bold;
    }

    .jidelni-listek-container {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 6%;
        margin-left: 10px;
        margin-right: 10px;
      }
      
      .jidelni-listek-odkazy {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        row-gap: 10px;
        justify-content: center;
        text-decoration: underline;
      }
      
      .jidelni-listek-odkazy li, .jidelni-listek-odkazy li a {
        font-family: "Libre Baskerville", serif;
        font-size: 9px;
        color: black;
        white-space: nowrap;
        font-weight: normal;
      }


      .jidelni-liskte-polevky-main{
        position: relative;
        text-align: left;
        font-family: "Libre Baskerville", serif;
        font-size: 23px;
        color: black;
        margin-top: 20%;
        font-weight: normal;
        margin-left: 7%;
      }


      .jidelni-liskte-polevky-ul{
        position: relative;
        text-align: left;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: black;
        margin-top: 5%;
        font-weight: normal;
        margin-left: 8%;
        line-height: 2;
        margin-right: 1%;
      }


      .jidelni-liskte-polevky-ul-i{
        position: relative;
        text-align: left;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: black;
        margin-top: 5%;
        font-weight: normal;
        margin-left: 12%;
        line-height: 2;
        margin-right: 1%;
      }

      .jidelni-liskte-polevky-ul-2{
        position: relative;
        text-align: left;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: black;
        margin-top: 1%;
        font-weight: normal;
        margin-left: 8%;
        line-height: 2;
        margin-right: 1%;
      }

      .jidelni-liskte-polevky-ul-3{
        position: relative;
        text-align: left;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: black;
        margin-top: 3%;
        font-weight: normal;
        margin-left: 8%;
        line-height: 2;
        margin-right: 1%;
      }


    .jidelni-liskte-polevky-ul-3 b{
        font-family: "Libre Baskerville", serif;
    }


    	
    .jidelni-lisktek-obrazek-container-1 {
        display: flex;
        justify-content: space-between;
        gap: 0.5%;
        margin-top: 20%;
      }
      
      .jidelni-lisktek-obrazek-1,
      .jidelni-lisktek-obrazek-2,
      .jidelni-lisktek-obrazek-3 {
        width: 33%;
        height: 100px;
        object-fit: cover;
        position: relative;
      }

      .jidelni-lisktek-obrazek-container-2 {
        display: flex;
        justify-content: space-between;
        gap: 0.5%;
        margin-top: 0.5%;
      }


      .jidelni-listek-stuha-1{
        position: relative;
        width: 55vh;
        height: 40px;
        margin-top: -5%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 10;
        filter: brightness(1.3);
    }
      


}