/* standard HD pro telefony */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }


    
    .firmy-mobile-pozadi-1{
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: 330vh;
        margin-top: 27%;
    }



    .firmy-h1{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 25px;
        margin-top: 15%; 
    }


    .ubytovani-main-p-text {
        position: relative;
        text-align: left;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        margin-top: 20%;
        margin-left: 7%;
        margin-right: 7%;
        line-height: 1.75;
    }


    .ubytovani-main-p-text b{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        margin-top: 15%;
        margin-left: 0%;
        margin-right: 0%;
        line-height: 1.75;
    }


    .ubytovani-ul-1, .ubytovani-ul-1 b{
        position: relative;
        text-align: left;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        margin-top: 15%;
        margin-left: 0%;
        margin-right: 7%;
        line-height: 1.75;
    }


    .ubytovani-ul-1 i{
        position: relative;
        text-align: left;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        margin-top: 15%;
        margin-left: 2%;
        margin-right: 7%;
        line-height: 1.75;
    }

    

    .firmy-prvni-img{
        width: 85%;
        margin-left: 7.5%;
        height: auto;
        position: relative;
        margin-top: 12%;
        border-radius: 5px;
    }


    .firmy-druhy-img{
        width: 40%;
        margin-left: 7.5%;
        height: 14vh;
        position: relative;
        margin-top: 5%;
        border-radius: 5px;
        object-fit: cover;
    }


    .firmy-treti-img{
        width: 40%;
        margin-left: 5%;
        height: 14vh;
        position: relative;
        margin-top: 5%;
        border-radius: 5px;
        object-fit: cover;
    }


    .firmy-ctvrty-img{
        width: 85%;
        margin-left: 7.5%;
        height: auto;
        position: relative;
        margin-top: 8%;
        border-radius: 5px;
    }


    

}