@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }


    .desktop-vypnuti{
        display: none;
    }


    .rezervace-body-pozadi{
        position: relative;
        width: 100%;
        height: 110vh !important;
        background-color: #f5e7cc;
    }


    .rezervace-top-img-1{
        position: relative;
        margin-top: 40%;
        width: 25%;
        margin-left: 37.5%;
    }


    .rezervace-zamluvte-si-termin{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 25px;
        margin-top: 3%;
    }


    .rezervace-zamluvte-si-termin-2{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 10px;
        margin-top: 3%;
        font-weight: normal;
    }


    .rezervace-div-container-1 {
        display: flex;
        justify-content: center;
        gap: 5%;
        position: relative;
        margin-top: 10%;
      }
      
      .rezervace-div-box-1 {
        width: 40%;
        height: 5vh;
        background-color: transparent;
        text-align: center;
        line-height: 5vh;
        border: 0.1px solid rgb(43, 43, 43);
        color: black;
        font-size: 12px;
        font-family: "Libre Baskerville", serif;
        border-radius: 10px;
      }


      .rezervace-div-box-2 {
        width: 40%;
        height: 5vh;
        background-color: white;
        text-align: center;
        line-height: 5vh;
        border: 0.1px solid rgb(43, 43, 43);
        color: black;
        font-size: 12px;
        font-family: "Libre Baskerville", serif;
        border-radius: 10px;
      }
      
      .rezervace-div-box-1 b {
        text-align: center;
        color: black;
        font-size: 12px;
        font-family: "Libre Baskerville", serif;
      }




      

      .rezervace-podminky-1{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 13px;
        margin-top: 20%;
      }


      .rezervace-podminky-text-1 {
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 8px;
        margin-top: 5%;
        margin-left: 10%;
        margin-right: 10%;
      }

      .rezervace-podminky-text-1 b, .rezervace-podminky-text-2 b{
        font-family: "Libre Baskerville", serif;
        font-size: 8px;
      }
      
      .rezervace-podminky-text-2 {
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 8px;
        margin-top: 5%;
        margin-left: 12%;
        margin-right: 12%;
      }


      .rezervace-footer-text{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 10px;
        margin-top: 20%; 
      }


}