@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1366px) and (max-width: 1440px) {



    .desktop-menu-jidelni-listek {
        position: absolute;
        left: 50%;
        top: 210%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 800px;
    }
    
    .desktop-section-jidelni-listek {
        margin-bottom: 50px;
    }
    
    .desktop-title-jidelni-listek {
        font-size: 22px;
        font-weight: bold;
        border-bottom: 2px solid #a37b4c;
        display: inline-block;
        margin-bottom: 10px;
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
    }
    
    .desktop-section-jidelni-listek p {
        font-size: 18px;
        margin: 5px 0;
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
    }


    .desktop-jidelni-listek-footer{
        margin-top: 230% !important;
    }


    

}