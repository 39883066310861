@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media (min-width: 1600px) and (max-width: 1920px) {

    .mobile-vypnuti{
        display: none;
    }


    .desktop-navbar {
        margin-bottom: -22%;
    }

    .desktop-aktuality-h3-main-nadpis {
        position: absolute;
        font-family: "Libre Baskerville", serif;
        font-size: 25px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: 1.5%;
        margin-left: 12.5%;
        z-index: 999 !important;
    }

    .desktop-aktuality-h4-main-nadpis {
        position: absolute;
        font-family: "Libre Baskerville", serif;
        font-size: 15px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: 4%;
        margin-left: 12.5%;
        z-index: 999;
    }

    .desktop-aktuality-h3-main-telefon {
        position: absolute;
        font-family: "Libre Baskerville", serif;
        font-size: 35px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: 2%;
        margin-left: 68%;
        z-index: 999;
    }

    .desktop-aktuality-h3-main-email {
        position: absolute;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -1.5%;
        margin-left: 68.25%;
        z-index: 999;
        text-decoration: underline 1px solid rgb(48, 66, 122);
    }


    .desktop-balicky-text-1{
        font-family: "Libre Baskerville", serif !important;
        position: relative;
        text-align: center;
        color: #60260f;
        font-size: 50px;
        font-weight: 300;
        margin-top: 6.5%;
    }



    .desktop-balicky-text-2 {
        font-family: "Libre Baskerville", serif !important;
        position: absolute;
        color: #9a5e09;
        font-size: 30px;
        font-weight: bold;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }
    

    .desktop-aktuality-informace-1{
        font-family: "Libre Baskerville", serif !important;
        position: absolute;
        color: #553200;
        font-size: 16px;
        font-weight: normal;
        top: 73%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        padding: 5%;
        line-height: 1.75;
    }



    .desktop-aktuality-text-3{
        font-family: "Libre Baskerville", serif !important;
        position: absolute;
        color: #9a5e09;
        font-size: 15px;
        font-weight: bold;
        top: 85%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center; 
    }


    .desktop-aktuality-jidelni-listek-1{
        position: absolute;
        top: 97%;
        left: 20%;
        width: 30%;
        height: auto;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;

    }


    .desktop-aktuality-jidelni-listek-2{
        position: absolute;
        top: 97%;
        left: 50%;
        width: 30%;
        height: auto;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }


    .desktop-balicky-text-main {
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 30px;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    }


    .desktop-aktuality-text-second {
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 13px;
        top: 45%;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        font-weight: normal;
        line-height: 1.75;
    }


    .desktop-aktuality-datum-akce{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 13px;
        top: 65%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
        text-decoration: underline;
    }


    .desktop-aktuality-datum-akce b{
        font-family: "Libre Baskerville", serif !important;
    }


    .desktop-aktuality-img-main{
        position: absolute;
        width: 50%;
        height: auto;
        left: 25%;
        top: 85%;
    }




/* ----------------------------------NOVE STYLY ----------------------------------- */

    .desktop-balicky-body{
        background-color: white;
        position: absolute;
        width: 100%;
        height: 27vh;
        top: 20%;
        z-index: 10;
        left: 0;
    }

    .desktop-balicky-fotka-main-1{
        position: absolute;
        width: 100%;
        height: 40%;
        object-fit: cover;
        left: 0% !important;
        top: 45%;
    }



    .desktop-aktuality-text-main{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 45px;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
    }


    .desktop-balicky-1-text-main{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 45px;
        top: 90%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
    }


    .desktop-balicky-text-second-1 {
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 13px;
        top: 102%;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        font-weight: normal;
        line-height: 1.75;
    }



    .desktop-balicky-text-second-2 {
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 13px;
        top: 115%;
        left: 50%;
        transform: translateX(-50%);
        width: 50%;
        font-weight: normal;
        line-height: 2;
    }


    .desktop-balicky-text-second-2 b {
        font-family: "Libre Baskerville", serif !important;
    }



    .desktop-balicky-obsah-balicku{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: left;
        font-size: 25px;
        top: 151%;
        left: 44%;
        transform: translateX(-50%);
        width: 50%;
        font-weight: normal;
        line-height: 2;
    }



    .desktop-balicky-obsah-balicku-2{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: left;
        font-size: 15px;
        top: 159%;
        left: 44%;
        transform: translateX(-50%);
        width: 50%;
        font-weight: normal;
        line-height: 2;
    }



    .desktop-balicky-fotka-main-2{
        position: absolute;
        width: 30%;
        height: 33%;
        object-fit: cover;
        left: 56% !important;
        top: 153%;
        border-radius: 10px;
        transition: all 0.5s ease-in-out;
    }


    .desktop-balicky-fotka-main-3{
        position: absolute;
        width: 13%;
        height: 15%;
        object-fit: cover;
        left: 52% !important;
        top: 174%;
        border-radius: 5px;
        transition: all 0.5s ease-in-out;
    }


    .desktop-balicky-fotka-main-2:hover, .desktop-balicky-fotka-main-3:hover{
        transform: scale(1.1);
    }



    .desktop-balicky-footer{
        margin-top: 170%;
    }



    .desktop-balicky-cena-balicku{
            position: absolute;
            color: #531d08;
            font-family: "Libre Baskerville", serif !important;
            text-align: center;
            font-size: 25px;
            top: 205%;
            left: 50%;
            transform: translateX(-50%);
            width: 50%;
            font-weight: normal;
            line-height: 2;
    }



    .desktop-balicky-ceny-tabulka {
        width: 65%;
        margin: 20px 0;
        position: absolute;
        top: 215%;
        left: 17.5%;
        font-family: "Libre Baskerville", serif !important;
        font-size: 15px;
    
        border-radius: 10px;
        overflow: hidden;
        
        border: 1px solid #531d08;
        border-collapse: separate;
        border-spacing: 0;
    }
    
    th {
        background-color: #531d08;
        color: white;
        padding: 10px;
        text-align: left;
        font-family: "Libre Baskerville", serif !important;
        font-size: 15px;
    }
    
    td {
        border-bottom: 1px solid #737373;
        padding: 8px;
        text-align: right;
        font-family: "Libre Baskerville", serif !important;
        font-size: 15px;
    }
    
    td:first-child {
        text-align: left;
        font-weight: bold;
    }
    
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }


    .desktop-balicky-text-pod-tabulku{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 15px;
        top: 250%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%; /* 100% - 25% zleva - 25% zprava = 50% */
        font-weight: normal;
        line-height: 2;
    }
    



}