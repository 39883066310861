/* standard HD pro telefony */

/* standard HD pro telefony */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    body{
        background-color: #F5E7CC;
    }

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    .desktop-vypnuti, .desktop-footer-kontakt-1{
        display: none;
    }

}