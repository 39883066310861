@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }


    .desktop-vypnuti{
        display: none;
    }

 
    .svatby-main-fotka-1 {
        width: 100%;
        height: 20%;
        object-fit: cover;
        position: relative;
        margin-top: 20%;
        border: none !important;
      }


      .svatby-main-fotka-2, .svatby-main-fotka-3 {
        width: 49.7%;
        height: 180px;
        margin-top: -0.5%;
        display: inline-block;
        vertical-align: top;
        object-fit: cover;
      }
      
      .svatby-main-fotka-2 {
        margin-right: 0.3%;
      }
      
      .svatby-main-fotka-3 {
        margin-left: 0.3%;
      }
      
      
      
      .svatby-body-pozadi {
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: auto;
        margin-top: 0;
    }
    


    .svatby-main-text-v-pozadi-1{
        position: relative;
        font-weight: lighter;
        color: black;
        font-size: 18px;
        font-family: "Libre Baskerville", serif;
        margin-left: 7%;
        margin-top: 15%;
    }



    .svatby-main-text-v-pozadi-2{
        position: relative;
        font-weight: bold;
        color: black;
        font-size: 22px;
        font-family: "Libre Baskerville", serif;
        margin-left: 7%;
        margin-top: 1.5%;
    }



    .svatby-main-text-v-pozadi-3{
        position: relative;
        font-weight: lighter;
        color: black;
        font-size: 11px;
        font-family: "Libre Baskerville", serif;
        margin-left: 7%;
        margin-top: 6%;
        text-align: left;
        margin-right: 9%;
        line-height: 1.5;
    }



    .svatby-main-button-1{
        position: relative;
        width: 35%;
        height: 5vh;
        background-color: #60260F;
        border-radius: 5px;
        border: none;
        margin-left: 7%;
        margin-top: 10%;

        color: white;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 15px;
        font-weight: bold;
    }


    .svatby-main-button-1 a{
        color: white;
        text-decoration: none;
        font-family: "Libre Baskerville", serif;
        font-size: 15px;
        font-weight: normal;
        cursor: pointer;
    }


    .rezervace-cursor-pointer, .rezervace-cursor-pointer a{
        cursor: pointer;
    }



    .svatby-second-fotka-1{
        width: 95%;
        height: auto;
        object-fit: cover;
        position: relative;
        margin-top: 20%;
        border: none !important;
        margin-left: 2.5%;
        border-radius: 5px;
    }



    .svatby-second-text-v-pozadi-1{
        position: relative;
        font-weight: bold;
        color: black;
        font-size: 18px;
        font-family: "Libre Baskerville", serif;
        margin-left: 3%;
        margin-top: 7%;
    }


    .svatby-second-text-v-pozadi-2{
        position: relative;
        font-weight: lighter;
        color: black;
        font-size: 12px;
        font-family: "Libre Baskerville", serif;
        margin-left: 3%;
        margin-top: 3%;
        margin-right: 10%;
        line-height: 1.5;
    }


    .hospudka-pod-rablinu-kontaktni-informace-svatby{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 23px;
        font-weight: bold;
        margin-top: 5%;
    }


    .hospudka-pod-rablinu-kontaktni-informace-svatby-2{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 13px;
        font-weight: lighter;
        margin-top: 1%;
        margin-bottom: 10%;
    }


    .svatby-main-fotka-4, .svatby-main-fotka-5, .svatby-main-fotka-6, .svatby-main-fotka-7 {
        width: 49.7%;
        height: 180px;
        margin-top: 0.5%;
        display: inline-block;
        vertical-align: top;
        object-fit: cover;
      }
      
      .svatby-main-fotka-4, .svatby-main-fotka-6 {
        margin-right: 0.3%;
      }
      
      .svatby-main-fotka-5, .svatby-main-fotka-7 {
        margin-left: 0.3%;
      }


      

      .hospudka-pod-rablinu-kontaktni-informace-svatby-3{
        position: relative;
        font-size: 28px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 25%;
        z-index: 2;
        font-weight: normal;
    }

}