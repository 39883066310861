/* standard HD pro telefony */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }

    

    .hospudka-pod-rablinu-stuha-1{
        position: fixed;
        width: 65vh;
        height: 30px;
        margin-top: -5%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 998 !important;
        filter: brightness(1.3);
    }


    .navar-pozadi{
        position: fixed;
        width: 100%;
        height: 100px;
        background-color: white;
        z-index: 997 !important;
    }

    .pozadi-homepage-mobile{
        background-color: #F5E7CC;
    }
        
    .hospudka-pod-rablinu-nazev-1{
        position: fixed;
        color: #30427A;
        font-family: "Libre Baskerville", serif;
        font-size: 21px;
        margin-top: 7.5%;
        margin-left: 3%;
        font-weight: bold;
        z-index: 999 !important;
    }

    .hospudka-pod-rablinu-nazev-2{
        position: fixed;
        color: #30427A;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        margin-top: 15%;
        margin-left: 3%;
        font-weight: normal;
        z-index: 999 !important;
    }
    

    .hospudka-pod-rablinu-stuha-2{
        position: fixed;
        width: 70vh;
        height: 50px;
        margin-top: 17.25%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 998 !important;
        filter: brightness(1.3);

    }


    .hospudka-pod-rablinu-1{
        position: relative !important;
        width: 100%;
        height: auto;
        margin-top: 25%;
        object-fit: cover;
        z-index: 1 !important;
        left: 0vh;
    }


    .hospudka-pod-rablinu-stuha-3{
        position: relative;
        width: 70vh;
        height: 50px;
        margin-top: -8%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 10;
        filter: brightness(1.3);
    }


    .zeleny-prvek-1{
        position: relative;
        width: 100%;
        height: 57vh;
        background-color: #F5E7CC;
        margin-top: -3%;
        z-index: 1;
        overflow-y: hidden;
    }


    .hospudka-pod-rablinu-nazev-main{
        position: relative;
        font-size: 23px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 8%;
        z-index: 2;
        margin-bottom: -4%;
    }





    .info-o-hospode-text-2{
        position: relative;
        font-size: 13px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: -3%;
        z-index: 2;
        font-weight: normal;
        padding: 10%;
        line-height: 1.5;
    }


    .hospudka-pod-rablinu-logo-main-1{
        position: relative;
        width: 20%;
        height: auto;
        margin-top: 7%;
        z-index: 1;
        margin-left: 40%;
        z-index: 1; 
    }


    .info-o-hospode-text-3{
        position: relative;
        font-size: 14px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: -15%;
        z-index: 2;
        font-weight: normal;
        padding: 10%;
        line-height: 1.5;
    }


    .hospudka-pod-rablinu-stuha-4{
        position: relative;
        width: 70vh;
        height: 50px;
        margin-top: 3;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 10;
        filter: brightness(1.3);
    }



    .hospudka-pod-rablinu-logo-2{
        position: relative;
        width: 30%;
        height: auto;
        margin-top: 7%;
        z-index: 1;
        margin-left: 35%;
        z-index: 1; 
    }
 

    .hospudka-pod-rablinu-zamluvte-si-termin{
        position: relative;
        font-size: 28px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 3%;
        z-index: 2;
        font-weight: normal;
    }


    .hospudka-pod-rablinu-zamluvte-si-termin-2{
        position: relative;
        font-size: 13px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 1%;
        z-index: 2;
        font-weight: lighter;
    }


    .hospudka-pod-rablinu-zamluvte-si-termin-tlacitko{
        position: relative;
        width: 50%;
        height: 6vh;
        background-color: #60260F;
        text-align: center;
        color: white;
        font-family: "Libre Baskerville", serif;
        font-size: 18px;
        margin-top: 8%;
        border-radius: 7px;
        margin-left: 25%;
        border: none;
    }

    .hospudka-pod-rablinu-zamluvte-si-termin-tlacitko a{
        color: white;
        text-decoration: none !important;
    }



    .hospudka-pod-rablinu-pokoj-1{
        position: relative;
        width: 90%;
        height: auto;
        margin-top: 15%;
        z-index: 10;
        border-radius: 10px;
        margin-left: 5%;
    }


    .hospudka-pod-rablinu-pokoj-2{
        position: relative;
        width: 90%;
        height: auto;
        margin-top: 7%;
        z-index: 10;
        border-radius: 10px;
        margin-left: 5%;
    }
    

    
    .hospudka-pod-rablinu-stuha-5{
        position: relative;
        width: 70vh;
        height: 50px;
        margin-top: 1%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 10;
        filter: brightness(1.3);
    }


    .hospudka-pod-rablinu-aktuality{
        position: relative;
        font-size: 32px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 10%;
        z-index: 2;
        font-weight: normal;
    }


    .hospudka-pod-rablinu-logo-3{
        position: relative;
        width: 25%;
        height: auto;
        margin-top: 2%;
        z-index: 1;
        margin-left: 37.5%;
        z-index: 1; 
    }
    

    .hospudka-pod-rablinu-aktuality-tlacitko{
        position: relative;
        width: 60%;
        height: 6vh;
        background-color: #285095;
        text-align: center;
        color: white;
        font-family: "Libre Baskerville", serif;
        font-size: 18px;
        margin-top: 8%;
        border-radius: 7px;
        margin-left: 20%;
        border: none;
    }

    .mobile-pozadi-1-white{
        background-color: #FFF8EC;
        width: 100%;
        height: 30vh;
        position: relative;
        margin-top: -3.5%;
        border-bottom: 5px solid white;
    }


    .mobile-pozadi-2-white{
        background-color: #FFF8EC;
        width: 100%;
        height: 30vh;
        position: relative;
        margin-top: -3.5%;
        border-bottom: 5px solid white;
    }

    .mapa-1{
        position: relative;
        width: 100%;
        height: 30vh;
        margin-top: 8%;
        z-index: 10;
        margin-left: 0%;
        border: none;
        border-bottom: 3px solid white;
        border-top: 3px solid white;
    }


    .hneda-footer{
        position: relative;
        width: 100%;
        height: 58vh;
        margin-top: -3%;
        z-index: 10;
        margin-left: 0%;
        border: none;
        background-color: #60260F;
    }



    
    .hospudka-pod-rablinu-oteviraci-doba{
        position: relative;
        font-size: 32px;
        font-family: "Libre Baskerville", serif;
        color: white;
        text-align: center;
        margin-top: 14%;
        z-index: 2;
        font-weight: normal;
        text-decoration: white underline;
    }


    .oteviraci-doba-1{
        position: relative;
        font-size: 14px;
        font-family: "Libre Baskerville", serif;
        color: white;
        text-align: left;
        margin-top: 10%;
        margin-left: 5%;
        z-index: 2;
        font-weight: normal;
    }


    .oteviraci-doba-2{
        position: relative;
        font-size: 14px;
        font-family: "Libre Baskerville", serif;
        color: white;
        text-align: left;
        margin-top: 6%;
        margin-left: 5%;
        z-index: 2;
        font-weight: normal;
    }


    .hospudka-pod-rablinu-stuha-6{
        position: relative;
        width: 100vh;
        height: 50px;
        margin-top: -8%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 10;
        filter: brightness(1.3);
    }


    .hospudka-pod-rablinu-kontaktni-informace{
        position: relative;
        font-size: 28px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 10%;
        z-index: 2;
        font-weight: normal;
    }


    .hospudka-pod-rablinu-kontaktni-informace-2{
        position: relative;
        font-size: 20px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 12%;
        z-index: 2;
        font-weight: normal;  
    }


    .hospudka-pod-rablinu-kontaktni-informace-3{
        position: relative;
        font-size: 22px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 4%;
        z-index: 2;
        font-weight: bold;
        margin-bottom: 15%; 
    }


    .hospudka-pod-rablinu-kontaktni-informace-4{
        position: relative;
        font-size: 13px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 5%;
        z-index: 2;
        font-weight: bold;
        text-decoration: underline;
    }


    .hospudka-pod-rablinu-kontaktni-informace-5{
        position: relative;
        font-size: 13px;
        font-family: "Libre Baskerville", serif;
        color: black;
        text-align: center;
        margin-top: 2%;
        z-index: 2;
        font-weight: bold;
        text-decoration: underline;
        margin-bottom: 15%;
    }


    .socialni-site-odkazy {
        position: relative;
        display: flex;
        justify-content: center;
        gap: 5%;
        margin-top: 6%;
        margin-bottom: 13%;
      }
      
      .socialni-site-odkazy a {
        display: flex;
        align-items: center;
        font-size: 24px;
        text-decoration: none;
        color: inherit;
      }


      .chraneno-autorskými-právy {
        position: relative;
        font-size: 12px;
        font-family: "Libre Baskerville", serif;
        color: white;
        text-align: center;
        margin-top: -3%;
        z-index: 2;
        font-weight: bold;
        
        width: 100%;
        background-color: #30427A;
        height: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
    
}