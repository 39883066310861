@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
}

@media screen and (min-width: 800px) and (max-width: 2560px) {

 

    .desktop-footer {
        position: relative;
        background-color: #32180d;
        width: 100%;
        height: 70vh;
        left: 0%;
    }


    .desktop-oteviraci-doba{
        color: white;
        margin-top: 4%;
        margin-left: 15%;
        font-family: "Libre Baskerville", serif !important;
        position: relative;
        text-align: left;
        font-size: 25px;
    }


    .desktop-oteviraci-doba-div{
        margin-top: -1%;
        margin-left: 15%;
        position: relative;
        width: 4%;
        height: 0.25vh;
        background-color: white;
        border-radius: 10px;
    }


    .desktop-oteviraci-hodiny{
        position: absolute;
        color: white;
        margin: 0 0 0% 0;
        left: 15%;
        margin-top: 1%;
        line-height: 2.5;
    }



    .desktop-obrazek-footer-logo{
        position: absolute;
        width: 15%;
        height: auto;
        margin-left: 42.5%;
        top: 30%;
      }


      .desktop-vse-ostatni{
        position: absolute;
        color: white;
        margin: 0 0 0% 0;
        left: 69%;
        margin-top: 1%;
        line-height: 2.5;
    }


    .desktop-footer-telefon{
        color: white;
        margin-top: 0%;
        left: 0%;
        font-family: "Libre Baskerville", serif !important;
        position: absolute;
        text-align: left;
        font-size: 25px;
    }

    .desktop-ikona-container{
        font-size: 30px;
    }

    .desktop-ikona{
        margin-right: 10%;
        color: white;
        text-decoration: underline;
    }


    .desktop-footer-email{
        color: rgb(255, 194, 80);
        text-decoration: none;
    }


    .desktop-copyright-footer-div{
        position: absolute;
        background-color: #160d0a;
        width: 99.2%;
        height: 5vh;
        left: 0.4%;
        overflow-y: hidden !important;
        z-index: 6;
    }

    .desktop-copyright-footer{
        color: white;
        font-family: "Libre Baskerville", serif !important;
        font-size: 14px;
        text-align: center;
        margin-top: 0.8% ;
        z-index: 7;
    }

    
}