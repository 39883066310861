@media (min-width: 1366px) and (max-width: 1919px) {

    body {
        background-color: #f5e7cc;
    }

    .mobile-vypnuti {
        display: none;
    }

.desktop-aktuality-text-1{
    z-index: 1 !important;
    margin-top: 4% !important;
}


.desktop-firmy-2{
    margin-top: 7% !important;
}


.desktop-firmy-nabidka-1, .desktop-firmy-prestavka-na-kavu, .desktop-firmy-prestavka-na-kavu-verze-2,
.desktop-firmy-prestavka-na-kavu-verze-3, .desktop-firmy-prestavka-na-kavu-verze-4, .desktop-firmy-prestavka-na-kavu-verze-5,
.desktop-firmy-prestavka-na-kavu-verze-6, .desktop-firmy-prestavka3-na-kavu, .desktop-firmy-prestavka3-na-kavu-verze-2,
.desktop-firmy-prestavka3-na-kavu-verze-3, .desktop-firmy-prestavka3-na-kavu-verze-4, .desktop-firmy-prestavka3-na-kavu-verze-5,
.desktop-firmy-prestavka3-na-kavu-verze-6, .desktop-firmy-prestavka3-na-kavu-verze-7, .desktop-firmy-prestavka4-na-kavu,
.desktop-firmy-prestavka4-na-kavu-verze-3, .desktop-firmy-prestavka4-na-kavu-verze-4, .desktop-firmy-prestavka4-na-kavu-verze-5,
.desktop-firmy-prestavka4-na-kavu-verze-6, .desktop-firmy-prestavka4-na-kavu-verze-7, .desktop-firmy-prestavka4-na-kavu-verze-8,
.desktop-firmy-prestavka4-na-kavu-verze-9, .desktop-firmy-prestavka4-na-kavu-verze-10, .desktop-firmy-prestavka4-na-kavu-verze-11,
.desktop-firmy-prestavka4-na-kavu-verze-12, .desktop-firmy-prestavka4-na-kavu-verze-13, .desktop-firmy-prestavka4-na-kavu-verze-14,
.desktop-firmy-prestavka4-na-kavu-verze-15 {
    margin-top: 25% !important;
    margin-left: -5% !important;
}


.desktop-firmy-prestavka2-na-kavu, .desktop-firmy-prestavka2-na-kavu-verze-2,
.desktop-firmy-prestavka2-na-kavu-verze-3, .desktop-firmy-prestavka2-na-kavu-verze-4, .desktop-firmy-prestavka2-na-kavu-verze-5,
.desktop-firmy-prestavka2-na-kavu-verze-6 {
    margin-top: 25% !important;
}


.desktop-firmy-footer{
    margin-top: 210% !important;
}





.desktop-firmy-fotka-dolu-1{
    position: absolute;
    width: 60%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px;
    top: 355%;
    left: 20%;
}






}