/* standard HD pro telefony */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }


    
    .mobile-pozadi-bacliky{
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: 200%;
        margin-top: -30% !important;
    }



    .mobile-balicky-nazev-balicku-main{
        position: relative;
        text-align: center;
        margin: 45% 0%;
        font-family: "Libre Baskerville", serif;
        font-size: 22px;
        color: rgb(83, 29, 8);
    }




    .mobile-balicky-nazev-balicku-second{
        position: relative;
        text-align: center;
        margin: -40% 15%;
        font-family: "Libre Baskerville", serif;
        font-size: 10px;
        color: rgb(83, 29, 8);
        font-weight: normal;
        line-height: 1.75;
    }




    .mobile-balicky-obrazek-pod-second{
        position: relative;
        width: 85%;
        height: 25vh;
        object-fit: cover;
        margin-left: 7.5%;
        margin-top: 55%;
        border-radius: 10px;
    }



    
    .mobile-balicky-nazev-balicku-third{
        position: relative;
        text-align: center;
        margin: 15% 10%;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: rgb(83, 29, 8);
        font-weight: normal;
        line-height: 1.75;
    }






    .mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku{
        position: relative;
        text-align: center;
        margin: 30% 10% !important;
        font-family: "Libre Baskerville", serif;
        font-size: 20px;
        color: rgb(83, 29, 8);
        font-weight: bold;
        line-height: 1.75; 
    }



    .mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku2{
        position: relative;
        text-align: left;
        margin: -20% 13% !important;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: rgb(83, 29, 8);
        font-weight: normal;
        line-height: 1.75;
        margin-bottom: 5% !important;
    }


    .mobile-balicky-nazev-balicku-obsah-pobytoveho-balicku3{
        position: relative;
        text-align: left;
        margin: 5% 13% !important;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: rgb(83, 29, 8);
        font-weight: normal;
        line-height: 1.75; 
    }



 
.mobile-balicky-table {
    display: flex;
    flex-direction: column;
    margin-top: -15%;
    /* margin-left: 10%; */
    font-family: "Libre Baskerville", serif;
}

.mobile-balicky-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    font-family: "Libre Baskerville", serif;
    text-align: left;
    margin-left: 13%;
}

.mobile-balicky-label {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
    font-family: "Libre Baskerville", serif;
}

.mobile-balicky-price {
    font-size: 15px;
    /* margin-left: 10px; */
    margin-bottom: 10px;
    font-family: "Libre Baskerville", serif;
}

.mobile-balicky-price u {
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
}


.mobile-balicky-nazev-balicku-prijde-sem{
    position: relative;
    text-align: center;
    margin: 20% 10% !important;
    font-family: "Libre Baskerville", serif;
    font-size: 10px;
    color: rgb(83, 29, 8);
    font-weight: bold;
    line-height: 1.75; 
}










}