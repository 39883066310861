@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1350px) and (max-width: 1700px) {

    .desktop-odpoledni-menu-polevka-1{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 65%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-polevka-2{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 69%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-nabidka-smazat-tecky{
        color: transparent;
    }


    .desktop-odpoledni-menu-nadpis{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 20px;
        top: 85%;
        font-family: "Libre Baskerville", serif;
        text-decoration: 1px solid underline;
    }


    .desktop-odpoledni-menu-main-1{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 90%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-2{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 95%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-3{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 100%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-4{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 105%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-5{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 110%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-6{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 115%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-7{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 120%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-8{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 125%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-9{
        position: absolute;
        left: 40%;
        color: black;
        font-size: 16px;
        top: 130%;
        font-family: "Libre Baskerville", serif;
    }



    .desktop-menu-jidelni-listek2 {
        position: absolute;
        left: 50%;
        top: 120%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 800px;
    }


    .desktop-odpoledni-nabidka-footer{
        margin-top: 135%;
    }


    
}





/* -------------------------------------------------------------------------------------------------------- */





@media screen and (min-width: 1701px) and (max-width: 1920px) {

    .desktop-odpoledni-menu-polevka-1{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 65%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-polevka-2{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 69%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-nabidka-smazat-tecky{
        color: transparent;
    }


    .desktop-odpoledni-menu-nadpis{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 20px;
        top: 85%;
        font-family: "Libre Baskerville", serif;
        text-decoration: 1px solid underline;
    }


    .desktop-odpoledni-menu-main-1{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 90%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-2{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 95%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-3{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 100%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-4{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 105%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-5{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 110%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-6{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 115%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-7{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 120%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-8{
        position: absolute;
        left: 40%;
        transform: translateX(-50%);
        color: black;
        font-size: 16px;
        top: 125%;
        font-family: "Libre Baskerville", serif;
    }


    .desktop-odpoledni-menu-main-9{
        position: absolute;
        left: 40%;
        color: black;
        font-size: 16px;
        top: 130%;
        font-family: "Libre Baskerville", serif;
    }



    .desktop-menu-jidelni-listek2 {
        position: absolute;
        left: 50%;
        top: 120%;
        transform: translate(-50%, -50%);
        width: 80%;
        max-width: 800px;
    }


    .desktop-odpoledni-nabidka-footer{
        margin-top: 125%;
    }


    
}