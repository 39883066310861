@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media (min-width: 1920px) and (max-width: 2559px) {

    .mobile-vypnuti{
        display: none;
    }

   


    .desktop-svatby-prostory-img-main-1{
        position: absolute;
        width: 32%;
        height: 40%;
        object-fit: cover;
        top: 52%;
        left: 0%;
    }


    .desktop-svatby-prostory-img-main-2{
        position: absolute;
        width: 34%;
        height: 40%;
        object-fit: cover;
        top: 52%;
        left: 33%;
    }


    .desktop-svatby-prostory-img-main-3{
        position: absolute;
        width: 32%;
        height: 40%;
        object-fit: cover;
        top: 52%;
        left: 68%;
    }



    .desktop-svatby-prostory-text-1{
        position: relative;
        text-align: center;
        margin: 75% 10%;
        font-family: "Libre Baskerville", serif;
        font-size: 18px;
        color: rgb(96, 38, 15);
        line-height: 1.5;
        font-weight: normal;
    }


    .desktop-svatby-prostory-text-1 b{
        font-family: "Libre Baskerville", serif;
        font-size: 22px;
    }


      
    .desktop-svatby-prostory-div-1{
        margin-top: -65%;
        height: 80%;
        background-color: #faf0d9;
        position: relative;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }



    
    .desktop-svatby-prostory-text-2{
        position: relative;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 40px;
        color: rgb(96, 38, 15);
        line-height: 1.5;
        font-weight: normal; 
        margin-top: 8% !important;
    }




    .desktop-svatby-prostory-img-main-4{
        position: absolute;
        width: 32%;
        height: 40%;
        object-fit: cover;
        top: 225%;
        left: 0%;
    }


    .desktop-svatby-prostory-img-main-5{
        position: absolute;
        width: 34%;
        height: 40%;
        object-fit: cover;
        top: 225%;
        left: 33%;
    }


    .desktop-svatby-prostory-img-main-6{
        position: absolute;
        width: 32%;
        height: 40%;
        object-fit: cover;
        top: 225%;
        left: 68%;
    }



    .desktop-svatby-prostory-text-second-1{
        position: relative;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 20px;
        color: rgb(96, 38, 15);
        line-height: 1.5;
        font-weight: normal;
        margin-top: 30%;
        text-decoration: none !important;
    }


    .desktop-hpr-none-decoration-underline a{
        text-decoration: none !important;
    }







      














      














      














      














      














      














      














    

    .desktop-svatby-prostory-footer{
        margin-top: 10%;
    }
}