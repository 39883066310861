@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media (min-width: 1366px) and (max-width: 1920px) {

    .mobile-vypnuti{
        display: none;
    }



    .desktop-svatby-fotogalerie {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3%;
        justify-content: center;
        padding: 5%;
        margin-top: 45%;
        overflow-y: hidden;
    }
    
    .desktop-svatby-fotogalerie-img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        border-radius: 7px;
        transition: transform 0.3s ease-in-out;
    }
    
    .desktop-svatby-fotogalerie-img:hover {
        transform: scale(1.05);
    }






   

    .desktop-svatby-fotogalerie-footer{
        margin-top: 10%;
    }


}