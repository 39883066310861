@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 800px) and (max-width: 2560px) {

body{
    background-color: #f5e7cc;
}


.desktop-firmy-text-1{
    color: #32180d;
    position: absolute;
    top: 55%;
    text-align: center;
    left: 50%;
    transform: translate(-50%);
    line-height: 1.5;
}


.desktop-firmy-2{
    color: #32180d;
    position: absolute;
    top: 80%;
    text-align: left;
    left: 54%;
    transform: translate(-50%);
    margin-right: -20%;
    line-height: 2;
}


.desktop-firmy-rozsireni-1{
    position: relative;
    width: 100%;
    height: 100vh;
}



.desktop-firmy-nabidka-1{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 155%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 45px;
    font-family: "Libre Baskerville", serif;
}


.desktop-firmy-prestavka-na-kavu{
    position: absolute;
    color: #1f0e06;
    margin-top: 3%;
    top: 165%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    font-weight: lighter;
    text-decoration: 1px black underline;
}


.desktop-firmy-prestavka-na-kavu-verze-2{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 170%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka-na-kavu-verze-3{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 173%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka-na-kavu-verze-4{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 176%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka-na-kavu-verze-5{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 179%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka-na-kavu-verze-6{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 184%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: bold;
}


/* ------------ */


.desktop-firmy-prestavka2-na-kavu{
    position: absolute;
    color: #1f0e06;
    margin-top: 3%;
    top: 165%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 60%;
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    font-weight: lighter;
    text-decoration: 1px black underline;
}


.desktop-firmy-prestavka2-na-kavu-verze-2{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 170%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 60%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka2-na-kavu-verze-3{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 173%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 60%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka2-na-kavu-verze-4{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 176%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 60%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka2-na-kavu-verze-5{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 179%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 60%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka2-na-kavu-verze-6{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 184%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 60%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: bold;
}


/* ------------ */


.desktop-firmy-prestavka3-na-kavu{
    position: absolute;
    color: #1f0e06;
    margin-top: 3%;
    top: 205%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    font-weight: lighter;
    text-decoration: 1px black underline;
}


.desktop-firmy-prestavka3-na-kavu-verze-2{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 210%;
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka3-na-kavu-verze-3{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 213%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka3-na-kavu-verze-4{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 216%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka3-na-kavu-verze-5{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 219%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka3-na-kavu-verze-7{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 222%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka3-na-kavu-verze-6{
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 227%;
    /* left: 49.5%; */
    /* left: 50%; */
    /* transform: translate(-50%); */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: bold;
}


.desktop-firmy-prestavka3-na-kavu b, .desktop-firmy-prestavka3-na-kavu-verze-2 b, .desktop-firmy-prestavka3-na-kavu-verze-3 b,
.desktop-firmy-prestavka3-na-kavu-verze-4 b, .desktop-firmy-prestavka3-na-kavu-verze-5 b, .desktop-firmy-prestavka3-na-kavu-verze-6 b,
.desktop-firmy-prestavka3-na-kavu-verze-7 b{
    font-family: "Libre Baskerville", serif;
}


/* ----------------------------------------------- */

.desktop-firmy-prestavka4-na-kavu {
    position: absolute;
    color: #1f0e06;
    margin-top: 3%;
    top: 251%; /* Posunuto o -9% */
    left: 26%;
    font-size: 25px;
    font-family: "Libre Baskerville", serif;
    font-weight: lighter;
    text-decoration: 1px black underline;
}

.desktop-firmy-prestavka4-na-kavu-verze-2 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 254%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-3 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 257%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-4 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 260%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-5 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 263%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-6 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 266%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-7 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 269%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-8 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 272%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-9 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 275%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-10 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 278%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-11 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 281%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-12 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 284%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-13 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 287%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-14 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 290%; /* Posunuto o -9% */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: normal;
}

.desktop-firmy-prestavka4-na-kavu-verze-15 {
    position: absolute;
    color: #32180d;
    margin-top: 3%;
    top: 295%; /* Posunuto o -9% (původně +5%) */
    left: 26%;
    font-size: 15px;
    font-family: "Libre Baskerville", serif;
    font-weight: bold;
}



/* ----------------------------------------------- */



.desktop-homepage-h3-main-nadpis {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 25px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: 1.5%;
    margin-left: 12.5%;
    z-index: 999;
}

.desktop-homepage-h4-main-nadpis {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 15px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: -1%;
    margin-left: 12.5%;
    z-index: 999;
}

.desktop-homepage-h3-main-telefon {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 35px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: -4%;
    margin-left: 68%;
    z-index: 999;
}

.desktop-homepage-h3-main-email {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 12px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: -1.5%;
    margin-left: 68.25%;
    z-index: 999;
    text-decoration: underline 1px solid rgb(48, 66, 122);
}



.desktop-firmy-footer{
    margin-top: 250%;
}


.ubytovani-pod-h1{
    z-index: 1 !important;
}


.desktop-firmy-footer{
    margin-top: 190% !important;
}




.desktop-firmy-fotka-dolu-1{
    position: absolute;
    width: 60%;
    height: 50%;
    object-fit: cover;
    border-radius: 10px;
    top: 320%;
    left: 20%;
}






}