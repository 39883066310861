@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {

    .desktop-nl-main-1 {
        position: absolute;
        top: 59%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
        font-size: 23px;
        font-weight: normal;
        text-decoration: 1px solid black underline;
    }

/* 1 */

    .desktop-nealko-drink-1{
        position: absolute;
        top: 70%;
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        font-weight: normal;
        left: 25%;
    }


    .desktop-nealko-drink-podtrzeni-1{
        position: absolute;
        top: 75%;
        left: 25%;
        background-color: rgb(63, 40, 13);
        width: 20%;
        height: 0.1vh;

    }


    /* 2 */


    .desktop-nealko-drink-2{
        position: absolute;
        top: 75%;
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        font-weight: normal;
        left: 25%;
    }


    .desktop-nealko-drink-podtrzeni-2{
        position: absolute;
        top: 80%;
        left: 25%;
        background-color: rgb(63, 40, 13);
        width: 20%;
        height: 0.1vh;

    }




    /* 3 */


    .desktop-nealko-drink-3{
        position: absolute;
        top: 80%;
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        font-weight: normal;
        left: 25%;
    }


    .desktop-nealko-drink-podtrzeni-3{
        position: absolute;
        top: 85%;
        left: 25%;
        background-color: rgb(63, 40, 13);
        width: 20%;
        height: 0.1vh;

    }




    /* 4 */


    .desktop-nealko-drink-4{
        position: absolute;
        top: 85%;
        color: rgb(20, 20, 20);
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        font-weight: normal;
        left: 25%;
    }


    .desktop-nealko-drink-podtrzeni-4{
        position: absolute;
        top: 90%;
        left: 25%;
        background-color: rgb(63, 40, 13);
        width: 20%;
        height: 0.1vh;

    }


/* ---------------------------------------------- */



/* 1 */

.desktop-nealko3-drink-1{
    position: absolute;
    top: 70%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko3-drink-podtrzeni-1{
    position: absolute;
    top: 75%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 2 */


.desktop-nealko3-drink-2{
    position: absolute;
    top: 75%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko3-drink-podtrzeni-2{
    position: absolute;
    top: 80%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}




/* 3 */


.desktop-nealko3-drink-3{
    position: absolute;
    top: 80%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko3-drink-podtrzeni-3{
    position: absolute;
    top: 85%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}




/* 4 */


.desktop-nealko3-drink-4{
    position: absolute;
    top: 85%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko3-drink-podtrzeni-4{
    position: absolute;
    top: 90%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}

/* --------------------------------------------------- */


.desktop-napojovy-listek-mezera-1{
    color: transparent !important;
}




.desktop-napojovy-listek-zmenseni-1{
    position: relative;
    margin-top: -20%;
}


.desktop-nl-main-2{
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    font-weight: normal;
    text-decoration: 1px solid black underline; 
}


/* 1 */

.desktop-kava-1{
    position: absolute;
    top: 115%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko2-drink-podtrzeni-1{
    position: absolute;
    top: 120%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 2 */

.desktop-kava-2{
    position: absolute;
    top: 120%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko2-drink-podtrzeni-2{
    position: absolute;
    top: 125%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 3 */

.desktop-kava-3{
    position: absolute;
    top: 125%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko2-drink-podtrzeni-3{
    position: absolute;
    top: 130%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 4 */

.desktop-kava-4{
    position: absolute;
    top: 130%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko2-drink-podtrzeni-4{
    position: absolute;
    top: 135%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 5 */

.desktop-kava-5{
    position: absolute;
    top: 135%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko2-drink-podtrzeni-5{
    position: absolute;
    top: 140%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 6 */

.desktop-kava-6{
    position: absolute;
    top: 140%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko2-drink-podtrzeni-6{
    position: absolute;
    top: 145%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



/* 1 */

.desktop-kava2-1{
    position: absolute;
    top: 115%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko4-drink-podtrzeni-1{
    position: absolute;
    top: 120%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 2 */

.desktop-kava2-2{
    position: absolute;
    top: 120%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko4-drink-podtrzeni-2{
    position: absolute;
    top: 125%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 3 */

.desktop-kava2-3{
    position: absolute;
    top: 125%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko4-drink-podtrzeni-3{
    position: absolute;
    top: 130%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 4 */

.desktop-kava2-4{
    position: absolute;
    top: 130%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko4-drink-podtrzeni-4{
    position: absolute;
    top: 135%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 5 */

.desktop-kava2-5{
    position: absolute;
    top: 135%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko4-drink-podtrzeni-5{
    position: absolute;
    top: 140%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


.desktop-nl-main-3{
    position: absolute;
    top: 160%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    font-weight: normal;
    text-decoration: 1px solid black underline;
}



.desktop-pivo-zvetseni-1{
    width: 100%;
    position: relative;
    height: 50vh;
}

/* 1 */

.pivo-1{
    position: absolute;
    top: 170%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko5-drink-podtrzeni-1{
    position: absolute;
    top: 175%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 2 */

.pivo-2{
    position: absolute;
    top: 175%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko5-drink-podtrzeni-2{
    position: absolute;
    top: 180%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 3 */

.pivo-3{
    position: absolute;
    top: 180%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko5-drink-podtrzeni-3{
    position: absolute;
    top: 185%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 4 */

.pivo-4{
    position: absolute;
    top: 170%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko5-drink-podtrzeni-4{
    position: absolute;
    top: 175%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


/* 5 */

.pivo-5{
    position: absolute;
    top: 175%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko5-drink-podtrzeni-5{
    position: absolute;
    top: 180%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.desktop-nl-main-4{
    position: absolute;
    top: 200%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    font-weight: normal;
    text-decoration: 1px solid black underline; 
}

/* -------------------------------------------------------------------- */

.vino-1{
    position: absolute;
    top: 210%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko6-drink-podtrzeni-1{
    position: absolute;
    top: 215%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.vino-2{
    position: absolute;
    top: 215%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko6-drink-podtrzeni-2{
    position: absolute;
    top: 220%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;
}


.vino-3{
    position: absolute;
    top: 210%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko6-drink-podtrzeni-3{
    position: absolute;
    top: 215%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;
}



.vino-4{
    position: absolute;
    top: 215%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko6-drink-podtrzeni-4{
    position: absolute;
    top: 220%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;
}




.desktop-alko-zvetseni-1{
    width: 100%;
    position: relative;
    height: 30vh;
}



.desktop-nl-main-5{
    position: absolute;
    top: 235%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 23px;
    font-weight: normal;
    text-decoration: 1px solid black underline;
}



.alkohol-1{
    position: absolute;
    top: 245%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-1{
    position: absolute;
    top: 250%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-2{
    position: absolute;
    top: 250%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-2{
    position: absolute;
    top: 255%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-3{
    position: absolute;
    top: 255%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-3{
    position: absolute;
    top: 260%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-4{
    position: absolute;
    top: 260%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-4{
    position: absolute;
    top: 265%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-5{
    position: absolute;
    top: 265%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-5{
    position: absolute;
    top: 270%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-6{
    position: absolute;
    top: 270%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-6{
    position: absolute;
    top: 275%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-7{
    position: absolute;
    top: 275%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-7{
    position: absolute;
    top: 280%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-8{
    position: absolute;
    top: 280%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-8{
    position: absolute;
    top: 285%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol-9{
    position: absolute;
    top: 285%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 25%;
}


.desktop-nealko7-drink-podtrzeni-9{
    position: absolute;
    top: 290%;
    left: 25%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



/* ----------------------------------------------------------------------------------------------------------------------- */




.alkohol2-1{
    position: absolute;
    top: 245%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-1{
    position: absolute;
    top: 250%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-2{
    position: absolute;
    top: 250%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-2{
    position: absolute;
    top: 255%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-3{
    position: absolute;
    top: 255%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-3{
    position: absolute;
    top: 260%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-4{
    position: absolute;
    top: 260%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-4{
    position: absolute;
    top: 265%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-5{
    position: absolute;
    top: 265%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-5{
    position: absolute;
    top: 270%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-6{
    position: absolute;
    top: 270%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-6{
    position: absolute;
    top: 275%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-7{
    position: absolute;
    top: 275%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-7{
    position: absolute;
    top: 280%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-8{
    position: absolute;
    top: 280%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-8{
    position: absolute;
    top: 285%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}



.alkohol2-9{
    position: absolute;
    top: 285%;
    color: rgb(20, 20, 20);
    font-family: "Libre Baskerville", serif;
    font-size: 16px;
    font-weight: normal;
    left: 55%;
}


.desktop-nealko8-drink-podtrzeni-9{
    position: absolute;
    top: 290%;
    left: 55%;
    background-color: rgb(63, 40, 13);
    width: 20%;
    height: 0.1vh;

}


.desktop-napojovy-listek-footer{
    margin-top: 120%;
}





















}