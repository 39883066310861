/* --------------------- DESKTOP STYLES (1920px - 2559px) -------------------------------------------------------------------------- */
@media (min-width: 1920px) and (max-width: 2559px) {

    body {
        background-color: #f5e7cc;
    }

    .mobile-vypnuti {
        display: none;
    }

    .desktop-body {
        position: relative;
        width: 100%;
        height: 372vh;
        background-color: #f5e7cc;
        margin-top: 15%;
        overflow-y: hidden;
    }

    .desktop-navbar {
        margin-bottom: -40%;
    }

    .desktop-homepage-h3-main-nadpis {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 25px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: 1.5%;
        margin-left: 12.5%;
        z-index: 999;
    }

    .desktop-homepage-h4-main-nadpis {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 15px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -1%;
        margin-left: 12.5%;
        z-index: 999;
    }

    .desktop-homepage-h3-main-telefon {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 35px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -4%;
        margin-left: 68%;
        z-index: 999;
    }

    .desktop-homepage-h3-main-email {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -1.5%;
        margin-left: 68.25%;
        z-index: 999;
        text-decoration: underline 1px solid rgb(48, 66, 122);
    }

    .desktop-obrazek-homepage1 {
        position: relative;
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-top: -35%;
        left: 0%;
        z-index: 1;
        clip-path: inset(5% 0 30% 0);
    }

    .desktop-homepage-h1-main-nadpis {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 35px;
        color: #60260f;
        text-align: center;
        font-weight: bold;
        margin-top: 52%;
        left: 0%;
        z-index: 1;
    }

    .desktop-homepage-pod-nadpis-1 {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 15px;
        color: #60260f;
        text-align: center;
        font-weight: normal;
        margin: 3% 10%;
        line-height: 1.5;
    }

    .desktop-homepage-pod-nadpis-1-1 {
        font-family: "Libre Baskerville", serif !important;
        color: #60260f;
    }



    .desktop-objednavka-rezervace-1 {
        background-color: #faf0d9;
        width: 100%;
        height: 45vh;
        position: relative;
        margin-top: 6%;
        border-bottom: 1px solid rgb(61, 61, 131);
        border-top: 1px solid rgb(61, 61, 131);
    }

    .desktop-objednavka-rezervace-text-1 {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 34px;
        color: #60260f;
        text-align: center;
        font-weight: bold;
        margin-top: 3%;
    }

    .desktop-objednavka-rezervace-text-2 {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 18px;
        color: #60260f;
        text-align: center;
        font-weight: normal;
        margin-top: -1%;
    }

    .desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko {
        position: relative;
        width: 20%;
        height: 6vh;
        background-color: #60260F;
        text-align: center;
        color: white;
        font-family: "Libre Baskerville", serif;
        font-size: 17px;
        margin-top: 4%;
        border-radius: 7px;
        margin-left: 40%;
        border: none;
        cursor: pointer;
    }

    .desktop-hospudka-pod-rablinu-zamluvte-si-termin-tlacitko-2 {
        position: relative;
        width: 20%;
        height: 6vh;
        background-color: white;
        text-align: center;
        color: #60260F;
        font-family: "Libre Baskerville", serif;
        font-size: 17px;
        margin-top: 1.5%;
        border-radius: 7px;
        margin-left: 40%;
        border: 1px solid #60260F;
        cursor: pointer;
    }

    .desktop-obrazek-pod-rezervaci-1,
    .desktop-obrazek-pod-rezervaci-2,
    .desktop-obrazek-pod-rezervaci-3 {
        position: relative;
        width: 30%;
        height: 400px;
        object-fit: cover;
        margin-top: 8%;
        border-radius: 7px;
        transition: transform 0.3s ease-in-out;
    }
    
    .desktop-obrazek-pod-rezervaci-1 {
        margin-left: calc((100% - (3 * 30%)) / 4);
    }
    
    .desktop-obrazek-pod-rezervaci-2 {
        margin-left: calc((100% - (3 * 30%)) / 4);
    }
    
    .desktop-obrazek-pod-rezervaci-3 {
        margin-left: calc((100% - (3 * 30%)) / 4);
        margin-right: calc((100% - (3 * 30%)) / 4);
    }
    

    .desktop-obrazek-pod-rezervaci-1:hover,
    .desktop-obrazek-pod-rezervaci-2:hover,
    .desktop-obrazek-pod-rezervaci-3:hover {
        transform: scale(1.1);
    }


    .desktop-obrazek-pod-rezervaci-text{
        position: relative;
        text-align: center;
        color: #60260F;
        font-family: "Libre Baskerville", serif;
        font-size: 17px;
        margin-top: 2%;

    }


    .desktop-mapa-footer-1{
        position: relative !important;
        text-align: center;
        margin-top: 0% !important;
        width: 100% !important;
        max-height: 60vh !important;
    }



    .stuha-desktop-container-1 {
        position: absolute;
        top: 20.5%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1 !important;
    }

    .stuha-desktop-container-2 {
        position: absolute;
        top: -31.5%;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }
    

    .desktop-obrazek-homepage1-stuha {
        width: 30%;
        height: auto;
        object-fit: cover;
        
        object-position: left;
        transform: scaleX(1.15);
    }




}

/* --------------------- MOBILE STYLES ---------------------------------------------------------------------------------------------------------------------------------------- */
.navbar-mobile-vypnuti,
.mobile-nazev-navbar {
    display: none;
}


