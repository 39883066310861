@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
  font-family: "Roboto", serif;
  overflow-x: hidden !important;
}

.navbar {
  position: absolute;
  top: 15%;
  width: 100%;
  background-color: #60260f;
  color: white;
  padding: 25px 0;
  text-align: left;
  left: 0;
  font-weight: bold;
  display: flex;
  justify-content: left;
  z-index: 999;
  font-family: "Libre Baskerville", serif;
}

.navbar.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  font-family: "Libre Baskerville", serif;
}

.nav-links {
  display: flex;
  justify-content: center; /* Zarovnání na střed horizontálně */
  gap: 70px; /* Mezera mezi prvky */
  align-items: center; /* Vertikální zarovnání (pokud má význam) */
  margin: 0 auto; /* Automatické zarovnání na střed */
  font-family: "Libre Baskerville", serif;
  width: fit-content; /* Obsah zabírá pouze tolik místa, kolik potřebuje */
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 20px;
  transition: color 0.3s ease;
  font-family: "Libre Baskerville", serif;
}

.nav-links a:hover {
  color: white;
}

.dropdown {
  cursor: pointer;
}

.dropdown-container {
  display: flex;
  justify-content: center; /* Horizontální zarovnání na střed */
  align-items: center; /* Vertikální zarovnání na střed */
  flex-direction: column; /* Uspořádání prvků pod sebe */
  width: 250px;
  height: 100%; /* Pokud chcete plnou výšku rodiče */
  margin-top: 12%;
  background-color: #60260f;
  padding: 20px;
  border-radius: 0px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 2rem;
  font-family: "Libre Baskerville", serif;
}

.dropdown-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center !important; /* Zarovná všechny odkazy na střed */
  justify-content: center; /* Vycentruje obsah vertikálně */
  padding: 0;
  width: 100%; /* Zabrání horizontálnímu posunu */
  font-family: "Libre Baskerville", serif;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.dropdown-content a {
  color: white;
  text-decoration: none;
  padding: 10px;
  transition: background-color 0.3s ease;
  border-bottom: 0.5px solid white;
  text-align: center; /* Text v odkazech na střed */
  font-size: 18px;
  font-family: "Libre Baskerville", serif;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
}

.dropdown-content a:hover {
  background-color: #285095;
}



@media screen and (max-width: 768px) {

  .navbar,
  .navbar.fixed,
  .nav-links,
  .nav-links a,
  .nav-links a:hover,
  .dropdown,
  .dropdown-container,
  .dropdown-content,
  .dropdown-content a,
  .dropdown-content a:hover {
    display: none;
  }



}