@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1350px) and (max-width: 1920px) {

body{
    background-color: #f5e7cc;
}


.mobile-vypnuti{
    display: none;
}


.desktop-telefon-top{
    position: absolute;
    margin-top: 2%;
    margin-left: 65%;
    color: #60260f;
    font-size: 40px;
    font-weight: bold !important;
}


.desktop-text-top2{
    position: absolute;
    margin-top: 5%; 
    margin-left: 71.5%;
    color: #60260f;
    font-size: 15px;
    font-weight: normal !important;
    text-decoration: underline;
}

.desktop-text-top2:hover{
    color: #006025;
}


.desktop-obrazek-logo{
    position: relative;
    width: 9%;
    height: auto;
    margin-top: 1.5%;
    margin-left: 13%;
}


.desktop-obrazek-homepage1{
    position: absolute;
    width: 100%;
    height: auto;
    top: 22%;
    left: 0%;
    /* filter: brightness(80%); */
    z-index: 1;
}


.desktop-green-pozadi{
    position: absolute;
    width: 100%;
    height: 54vh;
    margin-top: 1%;
    background-color: #006025;
    top: 82%;
    z-index: 5;
    left: 0%;
}


.desktop-hospudka-pod-rablinu-text-1{
    font-family: "Libre Baskerville", serif !important;
    position: relative;
    text-align: center;
    color: white;
    font-size: 60px;
    font-weight: 600;
}


.desktop-obrazek-hospudka-pod-rablinu-1{
    position: relative;
    display: block;
    margin: auto;
    top: -1%;
    transform: translateY(-50%);
    width: 10%;
    height: auto;
}


.desktop-obrazek-hospudka-pod-rablinu-text-1 {
    position: relative;
    text-align: center;
    color: white;
    font-size: 19px;
    font-weight: 300;
    line-height: 1.5;
    margin-top: -1%;
}


.desktop-white-pozadi{
   position: absolute;
   width: 100%;
   height: 38vh;
   margin-top: 1%;
   background-color: white;
   top: 140%;
   z-index: 5;
   left: 0%; 
}


.desktop-aktualne-text-1{
    font-family: "Libre Baskerville", serif !important;
    position: relative;
    text-align: center;
    color: #32180d;
    font-size: 55px;
    font-weight: 300;  
}



.desktop-obrazek-hospudka-pod-rablinu-2{
    position: relative;
    display: block;
    margin: auto;
    top: -7%;
    transform: translateY(-50%);
    width: 8%;
    height: auto;
}


.desktop-button-aktualne-1{
    position: relative;
    display: block;
    margin: auto;
    margin-top: 3.5%;
    transform: translateY(-50%);
    width: 8%;
    height: auto;
    background-color: #60260f;
    color: white;
    text-align: center;
    padding: 15px;
    width: 15%;
    height: 6vh;
    border: none;
    font-family: "Roboto", serif !important;
    font-size: 19px;

    place-items: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}


.desktop-button-aktualne-1:hover{
    background-color: #006025;
}



.desktop-obrazek-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin: auto;
    margin-top: -20%;
    overflow: hidden;
  }
  
  .desktop-obrazek {
    width: 30%;
    height: auto;
    border-radius: 5px;
  }

  

  .desktop-body-pokracovani{
   position: relative;
   width: 100%;
   height: 75vh;
   margin-top: 1%;
   background-color: #f5e7cc;
   top: 140%;
   z-index: 5;
   left: 0%;
  }


  .desktop-white-pozadi-2{
   position: absolute;
   width: 100%;
   height: 75vh;
   margin-top: 43%;
   background-color: white;
   top: 140%;
   z-index: 6;
   left: 0%;
  }



  .desktop-aktualne-text-2{
    font-family: "Libre Baskerville", serif !important;
    position: relative;
    text-align: center;
    color: #32180d;
    font-size: 55px;
    font-weight: 300;
    margin-top: 2%;
    margin-bottom: 3%;
}



.desktop-mapa-footer-1{
    position: absolute;
    width: 100%;
    height: 47vh;
    margin-top: 10%;
    background-color: white;
    top: 0%;
    z-index: 6;
    left: 0%;
    border: none;
}


.desktop-aktualne-last-center-text{
    /* font-family: "Libre Baskerville", serif !important; */
    position: relative;
    text-align: center !important;
    color: #32180d;
    font-size: 20px;
    font-weight: 300 !important;
    top: 63.3%;
    text-decoration: none !important;
}

.desktop-aktualne-last-center-text-odkaz{
    color: #32180d;
    text-decoration: none !important;
}

.desktop-aktualne-last-center-text-odkaz:hover{
    color: #006025;
    font-weight: normal;
}


.desktop-obrazek-hospudka-pod-rablinu-3{
    position: relative;
    display: block;
    margin: auto;
    top: -4.5%;
    transform: translateY(-50%);
    width: 8%;
    height: auto;
}


.desktop-homepage-white-color{
    color: white;
    text-decoration: none;
}


/* ---------------------MOBILE SEKCE-----------------MOBILE SEKCE-----------------------MOBILE SEKCE---------------------- */

.navbar-mobile-vypnuti,.mobile-nazev-navbar{
    display: none;
}


}