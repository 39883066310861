/* standard HD pro telefony */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }


    .desktop-vypnuti{
        display: none;
    }


    .ubytovani-h1{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 25px;
        margin-top: 40%; 
    }
 
    .ubytovani-body-pozadi {
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: auto;
        /* height: 500vh; */
        margin-top: 0;
    }


    .ubytovani-pod-h1{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 11px;
        margin-top: 1.9%;
    }


    .ubytovani-prvni-img{
        width: 95%;
        margin-left: 2.5%;
        height: auto;
        position: relative;
        margin-top: 12%;
        border-radius: 5px;
    }

    .ubytovani-pod-h1-2, .ubytovani-pod-h1-2 b{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 11px;
        margin-top: 3%;
    }



    .ubytovani-info-icon-1{
        position: relative;
        margin-top: 10%;
        width: 15%;
        height: auto;
        margin-left: 8%;
        border-radius: 10px;
    }



    .ubytovani-radnom-text-2{
        position: relative;
        text-align: left;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 11px;
        margin-top: 10%;
        margin-left: 7%;
        margin-right: 13%;
    }



    .ubytovani-rezervace-button-1{
        border: none;
        position: relative;
        text-align: center;
        color: white;
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        margin-left: 7%;
        width: 45%;
        height: 5vh;
        background-color: #60260F;
        margin-top: 7%;
        border-radius: 5px;
    }



    .ubytovani-prvni-img-2{
        width: 95%;
        margin-left: 2.5%;
        height: auto;
        position: relative;
        margin-top: 15%;
        border-radius: 5px;
    }



    .ubytovani-prvni-img-3{
        width: 95%;
        margin-left: 2.5%;
        height: auto;
        position: relative;
        margin-top: 3%;
        border-radius: 5px;
    }


    .ubytovani-rezervace-vice-info-2-second{
        position: relative;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 16px;
        color: #60260F;
        margin-top: 5%;
        text-decoration: underline;
    }



}