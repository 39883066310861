@media (min-width: 1921px) and (max-width: 2560px) {

    body {
        background-color: #f5e7cc;
    }

    .mobile-vypnuti {
        display: none;
    }


    .desktop-aktuality-text-1{
        z-index: 1 !important;
    }





}