@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1355px) and (max-width: 1920px) {

body{
    background-color: #f5e7cc;
}



.desktop-kontakt-main-1{
    color: rgb(50, 24, 13);
    font-size: 23px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 60%;
}

.desktop-kontakt-main-2{
    color: rgb(50, 24, 13);
    font-size: 17px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 67%;
}


.desktop-kontakt-main-2-2{
    color: rgb(50, 24, 13);
    font-size: 17px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 70%;
}


.desktop-kontakt-main-3{
    color: rgb(50, 24, 13);
    font-size: 17px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 78%;
}


.desktop-kontakt-main-4{
    color: rgb(50, 24, 13);
    font-size: 17px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 86%;
}

.desktop-kontakt-main-4 a{
    text-decoration: none;
    color: rgb(133, 48, 12);
}


.desktop-kontakt-main-5{
    color: rgb(50, 24, 13);
    font-size: 17px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 89%;
}

.desktop-kontakt-main-5 a{
    text-decoration: none;
    color: rgb(133, 48, 12);
}


.desktop-kontakt-main-6{
    color: rgb(50, 24, 13);
    font-size: 17px;
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 100%;
}


.desktop-white-pozadi-2-kontakt{
    position: absolute;
    width: 100%;
    height: 40vh;
    margin-top: 50%;
    background-color: white;
    top: 20%;
    z-index: 6;
    left: 0%;
    overflow-y: hidden !important;
   }


   .desktop-kontakt-zmensovaci-div{
    position: relative;
    margin-bottom: -27%;
   }


   .desktop-footer-kontakt-1{
    margin-top: 90% !important;
   }


   .desktop-homepage-h3-main-nadpis {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 25px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: 1.5%;
    margin-left: 12.5%;
    z-index: 999;
}

.desktop-homepage-h4-main-nadpis {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 15px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: -1%;
    margin-left: 12.5%;
    z-index: 999;
}

.desktop-homepage-h3-main-telefon {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 35px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: -4%;
    margin-left: 68%;
    z-index: 999;
}

.desktop-homepage-h3-main-email {
    position: relative;
    font-family: "Libre Baskerville", serif;
    font-size: 12px;
    color: #531d08;
    text-align: left;
    font-weight: bold;
    margin-top: -1.5%;
    margin-left: 68.25%;
    z-index: 999;
    text-decoration: underline 1px solid rgb(48, 66, 122);
}

.desktop-aktualne-text-2{
    position: absolute;
    font-family: "Libre Baskerville", serif;
    font-size: 15px;
    color: #531d08;
    font-weight: bold;
    top: 120%;
    z-index: 999;
    text-decoration: underline 1px solid rgb(48, 66, 122);
    left: 50%;
    transform: translate(-50%, -50%);
}

.desktop-mapa-footer-1{
    height: 100% !important;
}



}