@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media (min-width: 1921px) and (max-width: 2560px) {

    .desktop-aktuality-body{
        position: absolute;
        width: 100%;
        height: 20%;
        top: 17%;
        background-color: whitesmoke;
    }


    .desktop-aktuality-text-1{
        position: absolute;
        text-align: center;
        font-size: 40px;
        font-weight: bold;
        font-family: "Libre Baskerville", serif;
        color: #32180d;
        z-index: 999 !important;
        top: 45%;
        transform: translate(-50%, -50%);
        left: 50%;
    }


    .desktop-galerie {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 3%;
        width: 90%;
        margin: -6% auto;
        overflow-y: hidden;
        position: absolute;
        left: 5%;
        cursor: pointer;
      }

      .desktop-galerie-2{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 3%;
        width: 90%;
        margin: -3% auto;
        overflow-y: hidden;
        position: absolute;
        left: 5%;
        cursor: pointer;
        height: 60vh;
      }
      
      .desktop-galerie {
        top: 60%;
      }
      
      .desktop-galerie-2 {
        top: 100%;
      }
      
      .desktop-galerie-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      
      .desktop-galerie-image-container img {
        width: 100%;
        height: auto;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
      
      .desktop-galerie-image-caption {
        width: 100%;
        background-color: white;
        color: #32180d;
        text-align: center;
        padding: 3vh 0;
        font-size: 20px;
        font-weight: bold;
        font-family: "Libre Baskerville", serif;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        text-decoration: none;
      }


      .desktop-footer-galier-1{
        margin-top: 95% !important;
      }


      .desktop-homepage-h3-main-nadpis {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 35px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: 2%;
        margin-left: 12.5%;
        z-index: 999;
    }

    .desktop-homepage-h4-main-nadpis {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 20px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -1%;
        margin-left: 12.5%;
        z-index: 999;
    }

    .desktop-homepage-h3-main-telefon {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 45px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -3.5%;
        margin-left: 68%;
        z-index: 999;
    }

    .desktop-homepage-h3-main-email {
        position: relative;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: #531d08;
        text-align: left;
        font-weight: bold;
        margin-top: -1.5%;
        margin-left: 68.25%;
        z-index: 999;
        text-decoration: underline 1px solid rgb(48, 66, 122);
    }
   

}