@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1440px) and (max-width: 1920px) {

    .desktop-denni-nabidka-img{
        position: absolute;
        width: 60%;
        height: auto;
        top: 55%;
        left: 20%;
        border-radius: 5px;
    }

}


@media screen and (min-width: 360px) and (max-width: 800px) {

    .denni-nabidka-changing-image-1{
        position: relative;
        width: 90%;
        height: auto;
        margin: 45% 5%;
        border-radius: 5px;
    }


}