@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (min-width: 1366px) and (max-width: 1919px) {

body{
    background-color: #f5e7cc;
}


.mobile-vypnuti{
    display: none;
}


.desktop-ubytovani-text-second {
    position: absolute;
    color: #531d08;
    font-family: "Libre Baskerville", serif !important;
    text-align: center;
    font-size: 13px;
    top: 50% !important;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    font-weight: normal;
    line-height: 1.75;
}


.desktop-ubytovani-text-second b{
    font-family: "Libre Baskerville", serif !important;
    font-size: 14px;
}



.desktop-ubytovani-zamluvte-si-termin-tlacitko {
    position: absolute;
    width: 20%;
    height: 6vh;
    background-color: #60260F;
    text-align: center;
    color: white;
    font-family: "Libre Baskerville", serif;
    font-size: 17px;
    top: 80%;
    border-radius: 7px;
    margin-left: 40%;
    border: none;
    cursor: pointer;
}



.desktop-obrazek-aktualne-pod-rezervaci-1,
.desktop-obrazek-aktualne-pod-rezervaci-2,
.desktop-obrazek-aktualne-pod-rezervaci-3 {
        position: relative;
        width: 30%;
        height: 300px;
        object-fit: cover;
        margin-top: 75%;
        border-radius: 7px;
        transition: transform 0.3s ease-in-out;
    }
    
    .desktop-obrazek-aktualne-pod-rezervaci-1,
    .desktop-obrazek-aktualne-pod-rezervaci-5,
    .desktop-obrazek-aktualne-pod-rezervaci-6  {
        margin-left: calc((100% - (3 * 30%)) / 4);
    }
    
    .desktop-obrazek-aktualne-pod-rezervaci-2 {
        margin-left: calc((100% - (3 * 30%)) / 4);
        transform: scale(1.1);
    }
    
    .desktop-obrazek-aktualne-pod-rezervaci-3,
    .desktop-obrazek-aktualne-pod-rezervaci-7 {
        margin-left: calc((100% - (3 * 30%)) / 4);
        margin-right: calc((100% - (3 * 30%)) / 4);
    }
    

    .desktop-obrazek-aktualne-pod-rezervaci-1:hover,
    /* .desktop-obrazek-aktualne-pod-rezervaci-2:hover, */
    .desktop-obrazek-aktualne-pod-rezervaci-3:hover,
    .desktop-obrazek-aktualne-pod-rezervaci-5:hover,
    .desktop-obrazek-aktualne-pod-rezervaci-6:hover,
    .desktop-obrazek-aktualne-pod-rezervaci-7:hover
    {
        transform: scale(1.025);
    }



    .desktop-ubytovani-text-second-2 {
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 13px;
        top: 143% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
    }




    .desktop-ubytovani-text-second-3{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 30px;
        top: 160% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
    }



    .desktop-ubytovani-text-second-4{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 15px;
        top: 170% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
    }



    .desktop-obrazek-aktualne-pod-rezervaci-4 {
            position: relative;
            width: 60%;
            height: 400px;
            object-fit: cover;
            margin-top: 25%;
            border-radius: 7px;
            transition: transform 0.3s ease-in-out;
            margin-left: 20%;
        }



    .desktop-ubytovani-text-second-5{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 25px;
        top: 250% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-weight: normal;
        line-height: 1.75;
    }



    .desktop-ubytovani-text-second-6{
        position: absolute;
        color: #531d08;
        font-family: "Libre Baskerville", serif !important;
        text-align: center;
        font-size: 13px;
        top: 259% !important;
        left: 50%;
        transform: translateX(-50%);
        width: 60%;
        font-weight: normal;
        line-height: 1.75;
    }




    .desktop-obrazek-aktualne-pod-rezervaci-5,
    .desktop-obrazek-aktualne-pod-rezervaci-6,
    .desktop-obrazek-aktualne-pod-rezervaci-7 {
            position: relative;
            width: 30%;
            height: 300px;
            object-fit: cover;
            margin-top: 20%;
            border-radius: 7px;
            transition: transform 0.3s ease-in-out;
        }



        .desktop-ubytovani-text-second-7{
            position: absolute;
            color: #531d08;
            font-family: "Libre Baskerville", serif !important;
            text-align: center;
            font-size: 30px;
            top: 325% !important;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            font-weight: normal;
            line-height: 1.75;
        }



        .desktop-ubytovani-text-second-8{
            position: absolute;
            color: #531d08;
            font-family: "Libre Baskerville", serif !important;
            text-align: left;
            font-size: 20px;
            top: 342% !important;
            left: 88%;
            transform: translateX(-50%);
            width: 100%;
            font-weight: normal;
            line-height: 1.75;
        }


        .desktop-ubytovani-text-second-8 b{
            font-family: "Libre Baskerville", serif !important;
        }



        .desktop-ubytovani-tabulka {
            width: 65%;
            margin: 20px 0;
            position: absolute;
            top: 345%;
            left: 17.5%;
            font-family: "Libre Baskerville", serif !important;
            font-size: 15px;
        
            border-radius: 10px;
            overflow: hidden;
            
            border: 1px solid #531d08;
            border-collapse: separate;
            border-spacing: 0;
        }
        



        .desktop-ubytovani-tabulka-2 {
            width: 50%;
            border-collapse: collapse;
            margin: 0% auto;
            font-size: 18px;
            display: none !important;
        }
        



































    .desktop-ubytovani-footer-main{
        margin-top: 65% !important;
    }


}