@media only screen and (max-width: 767px) { 

  @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

  *{
      font-family: "Roboto", serif;
      overflow-x: hidden !important;
  }

  .ikona{
    color: #30427A;
    position: fixed;
    z-index: 999;
    text-align: left;
    margin-left: 82%;
    font-size: 56px;
    margin-top: 6.5%;
  }

  .inside {
    background-color: white;
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    top: 0%;
    left: 0;
  }

  .links {
    text-align: left;
    margin-top: -20%;
    position: absolute;

    right: 0%;
    margin-right: 31%;

  }

  .links a {
    display: block;
    margin-bottom: 40px;
    font-size: 16px;
    color: #4660ad;
    text-decoration: none;
    font-weight: bold;

    white-space: nowrap;
    max-width: 100%;
    margin-right: 0% !important;
    padding-right: 100px; /* Přidá trochu prostoru vpravo */
  }

  .links a:hover {
    text-decoration: underline;
  }

  .fade-enter {
    transform: translateX(-100%);
    opacity: 0;
  }

  .fade-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.5s ease-out, opacity 0.3s ease-out;
  }

  .fade-exit {
    transform: translateX(0);
    opacity: 1;
  }

  .fade-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.5s ease-in, opacity 0.3s ease-in;
  }

  .submenu-container {
    position: relative;
  }

  .submenu-fade-enter {
    transform: translateX(-100%);
    opacity: 0;
  }

  .submenu-fade-enter-active {
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }

  .submenu-fade-exit {
    transform: translateX(0);
    opacity: 1;
  }

  .submenu-fade-exit-active {
    transform: translateX(-100%);
    opacity: 0;
    transition: transform 0.3s ease-in, opacity 0.3s ease-in;
  }

  .submenu {
    padding-left: 20px;
    display: block;
  }

  .submenu a {
    margin-bottom: 50px;
    font-size: 14px;
    color: #30427A;
    text-decoration: none;
    font-weight: weight;
    font-family: "Libre Baskerville", serif;
  }

  .caret {
    margin-left: 10px;
    transition: transform 0.3s;
  }

  .caret.open {
    transform: rotate(180deg);
  }

  .social-specialist-mobile-cyan{
    color: #30427A !important;
    font-weight: bold;
  }


  .mobile-react-native-icon-1{
    margin-right: 15%;
    color: #172f78;
  }




@media screen and (min-width: 1600px) and (max-width: 1920px) {

  .ikona,
  .inside,
  .links,
  .links a,
  .links a:hover,
  .fade-enter,
  .fade-enter-active,
  .fade-exit,
  .fade-exit-active,
  .submenu-container,
  .submenu-fade-enter,
  .submenu-fade-enter-active,
  .submenu-fade-exit,
  .submenu-fade-exit-active,
  .submenu,
  .submenu a,
  .caret,
  .caret.open,
  .mobile-react-native-icon-1,
  .social-specialist-mobile-cyan {
      display: none !important;
  }

}






}