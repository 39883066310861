/* standard HD pro telefony */

@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }




    .aktuality-text-pod-main{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 10px;
        margin: 3% 15%;
        font-weight: normal;
    }
    

    .aktuality-changing-image-1{
        position: relative;
        width: 90%;
        height: auto;
        margin: 15% 5%;
        border-radius: 5px;
    }


    .aktuality-changing-image-2{
        position: relative;
        width: 90%;
        height: auto;
        margin: 0% 5%;
        border-radius: 5px;
    }

    .aktuality-mobile-pozadi-1{
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: 90%;
        margin-top: 80% !important;
    }


    .mobile-aktuality-text-main{
        position: relative;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 18px;
        color: #531d08;
        margin: 45% 0%;
        font-weight: bold;
    }



    .mobile-aktuality-text-second{
        position: absolute;
        text-align: center;
        font-family: "Libre Baskerville", serif;
        font-size: 12px;
        color: #531d08;
        font-weight: normal;
        line-height: 1.5;
        top: 30%;
        margin-left: 7%;
        margin-right: 7%;
        z-index: 2 !important;
    }

}