@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }


    
    .fotogalerie-mobile-pozadi-1{
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: 675vh;
        margin-top: 27%;
    }

    

    .fotogalerie-pozadi-main{
        position: absolute;
        background-color: #F5E7CC;
        height: 250vh;
        width: 100%;
        margin-top: 3%;
    }

    .fotogalerie-pozadi-second{
        position: absolute;
        background-color: #FAF3E5;
        height: 10vh;
        width: 100%;
        margin-top: 25%;
    }

    .fotogalerie-text-main-1{
        position: relative;
        color: black;
        text-align: center;
        
        font-family: "Libre Baskerville", serif;
        font-size: 25px;
        margin-top: 7%;
    }


    .hospudka-pod-rablinu-stuha-fotogalerie-1{
        position: relative;
        width: 65vh;
        height: 35px;
        margin-top: 25%;
        object-fit: cover;
        transform: rotate(180deg);
        transform: scaleY(1);
        z-index: 1 !important;
        filter: brightness(1.3);
    }



    .fotogalerie-div-pred-fotkou-1{
        position: absolute;
        background-color: white;
        height: 15vh;
        width: 100%;
        margin-top: -3%;
    }


    .fotogalerie-svatby-1-fotka{
        position: relative;
        margin-top: 23%;
        width: 95%;
        height: 250px;
        object-fit: cover;
        margin-left: 2.5%;
        border-radius: 10px;
    }


      
    
}