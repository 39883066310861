@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik+Wet+Paint&display=swap');

*{
    font-family: "Roboto", serif;
    overflow-x: hidden !important;
}

@media screen and (max-width: 768px) {

    *{
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 0%;
        margin-bottom: 0%;
        overflow-x: hidden;
    }

    /* :root {
        --viewport-height: 100vh;
    }
    
    body {
        height: var(--viewport-height);
        overflow: hidden;
    } */

    .desktop-vypnuti{
        display: none;
    }


    
    .fotogalerie-svatby-main-fotka-1{
        position: relative;
        width: 100%;
        height: 250px;
        object-fit: cover;
        margin-top: 20%;
        border-top: 3px solid white;
    }


    .fotogalerie-svatby-text-pod-img, .fotogalerie-svatby-text-pod-img b{
        position: relative;
        text-align: center;
        color: black;
        font-family: "Libre Baskerville", serif;
        font-size: 15px;
        margin-top: 3%;
    }

    .fotogalerie-svatby-main-fotka-2{
        position: relative;
        width: 100%;
        height: 250px;
        object-fit: cover;
        margin-top: 0%;
        border-top: 3px solid white;
        border-bottom: 3px solid white;
    }

    .fotogalerie-mobile-pozadi-2{
        position: relative;
        background-color: #F5E7CC;
        width: 100%;
        height: 460vh;
        margin-top: 27%;
    }
      
    
}